<!--
 * @Author: your name
 * @Date: 2021-03-04 15:20:57
 * @LastEditTime: 2021-03-04 15:23:54
 * @LastEditors: Please set LastEditors
 * @Description: 智能推单
 * @FilePath: \sd-vue-admin\src\views\project\marketing\marketingPush\pushOrders\index.vue
-->
<!--  -->
<template>
  <div class="orderTest-container">智能推单</div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {}
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
